.generic.i7component {
  color: #fff;
  text-align: center;
  padding: 50px;
  background-repeat: no-repeat;
  background-position: top center;
  -webkit-background-size: cover;
  background-size: cover;
  position: relative;

  .component-content {
    position: relative; }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.2); } }
